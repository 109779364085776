<template>
  <div>
    <v-divider></v-divider>
    <div class="row">
      <div class="col-md-8 col-11 mx-auto">
        <div
          v-for="(item, index) in conteudo[tabAtiva]"
          :key="index"
          class="sobre-itens my-6"
          >
          <div class="badge-icon">
            <v-icon v-if="tabAtiva == 'tutorial'" :color="index % 2 == 0 ? 'white' : 'primary'" large>mdi-youtube</v-icon>
            <v-icon v-else :color="index % 2 == 0 ? 'white' : 'primary'" large>mdi-file-pdf</v-icon>
          </div>
          <v-card
            :class="index % 2 == 0 ? 'primary' : 'white'"
            :dark="index % 2 == 0"
            class="elevation-3"
            >
            <v-card-title class="pt-7" >{{ item.titulo }} </v-card-title>
            <v-card-text class="quebra-texto">{{ item.descricao }}</v-card-text>
            <div class="text-center pb-2" v-if="tabAtiva ==='tutorial'">
              <iframe width="95%" class="mx-auto" height="315" :src="`https://www.youtube.com/embed/${item.destinoConteudo}`"
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="text-center pb-2" v-else-if="tabAtiva === 'manual'">
              <v-btn @click="abrirArquivo(item.destinoConteudo)" text :color="index % 2 == 0 ? 'white' : 'primary'">ver manual</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import relatoriosService from '@/services/relatoriosService';

export default {
  data() {
    return {
      tabAtiva: 'tutorial',
      conteudo: {
        tutorial: [],
        manual: [],
      },
    };
  },
  created() {
    this.getInformacoes('tutorial');
    this.getInformacoes('manual');
  },
  methods: {
    async getInformacoes(tipoConteudo) {
      try {
        this.conteudo[tipoConteudo] = await relatoriosService.getSobre({ tipoConteudo });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao retransmitir, tente novamente mais tarde',
        });
      }
    },
    abrirArquivo(link) {
      window.open(link, '_blank');
    },
  },
};
</script>
<style lang="scss">
.sobre-itens {
  position: relative;
  .badge-icon {
    position: absolute;
    z-index: 2;
    right: 5px;
    top: 5px;

  }
}
.quebra-texto {
  word-break: break-word;
}
</style>
