import api from "@/services/api";
import appError from "@/utils/appError";

const resource = "dashboard";

export default {
  async listar({ dataInicial, dataFinal }) {
    try {
      const config = {
        headers: {
          "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_DASHBOARD,
        },
      };
      const { data } = await api.get(`${resource}/dadosResponsivo?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, config);
      return data.data;
    } catch (error) {
      const { response } = error;
      throw new appError(response, 400);
    }
  },
};
