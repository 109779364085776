import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=13e1f1d4"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=13e1f1d4&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VChip,VCol,VDatePicker,VDialog,VDivider,VIcon,VProgressCircular,VRow,VSheet,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13e1f1d4')) {
      api.createRecord('13e1f1d4', component.options)
    } else {
      api.reload('13e1f1d4', component.options)
    }
    module.hot.accept("./Dashboard.vue?vue&type=template&id=13e1f1d4", function () {
      api.rerender('13e1f1d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AreaLogada/VisaoGeral/Dashboard.vue"
export default component.exports