var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-8 col-11 mx-auto" }, [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.data.length > 0
        ? _c(
            "div",
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "card-notificacao pa-4 my-4 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.verProposta(
                        item.internalId,
                        item.guidProposta,
                        item.tipo
                      )
                    },
                  },
                },
                [
                  !item.visto
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "label-novo white--text",
                          attrs: { label: "", color: item.cor },
                        },
                        [_vm._v(" novo! ")]
                      )
                    : _vm._e(),
                  _c("v-sheet", {
                    staticClass: "barra",
                    attrs: {
                      color: item.cor,
                      elevation: "1",
                      height: "150",
                      "min-height": "100%",
                      width: "8",
                      rounded: "",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between align-center",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "text-subtitle-2 black--text text-uppercase font-weight-medium",
                            },
                            [_vm._v("Proposta nº " + _vm._s(item.nrProposta))]
                          ),
                          _c("v-card-text", [
                            _c("strong", [_vm._v(_vm._s(item.nomeCliente))]),
                            _c("br"),
                            _vm._v(
                              " data: " + _vm._s(item.dataAtualizacao) + " "
                            ),
                            _c("br"),
                            _vm._v(" motivo: " + _vm._s(item.descricao) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text elevation-0 px-4 py-1",
                          attrs: { text: "", color: item.cor },
                        },
                        [
                          _vm.editando === item.internalId
                            ? _c(
                                "div",
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      color: item.cor,
                                      indeterminate: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("v-icon", [_vm._v("mdi-chevron-right")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("div", [_vm._m(0)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-8" }, [
      _c("div", { staticClass: "text-center text-h6 primary--text" }, [
        _vm._v("Não há notificações."),
      ]),
      _c("div", { staticClass: "text-center mt-2" }, [
        _vm._v("Nenhuma notificação disponível no momento."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }