var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "visaogeral" } },
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Visão Geral" } },
        [
          _c("v-btn", {
            attrs: { icon: "" },
            on: {
              click: function ($event) {
                _vm.dialogFiltro = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "visao-tabs",
          staticStyle: { height: "68px" },
          attrs: {
            "background-color": "transparent",
            centered: "",
            lighter: "",
            "icons-and-text": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.pages, function (page, index) {
          return _c(
            "v-tab",
            {
              key: index,
              staticClass: "py-2",
              staticStyle: { height: "85px" },
              attrs: { href: `#tab-${index}` },
            },
            [
              _c("div", {
                staticClass: "tab-name",
                domProps: { innerHTML: _vm._s(page.nome) },
              }),
              _c(
                "v-badge",
                {
                  attrs: {
                    color:
                      page.nome === "Alterações" && _vm.notificacoesAlertas > 0
                        ? "red"
                        : "transparent",
                    overlap: "",
                    content:
                      page.nome === "Alterações" && _vm.notificacoesAlertas > 0
                        ? _vm.notificacoesAlertas
                        : "",
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(page.icon) + " ")])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("div", { staticClass: "border-tabs" }),
      _c(
        "v-tabs-items",
        {
          staticClass: "elevation-0",
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.pages, function (page, index) {
          return _c(
            "v-tab-item",
            { key: index, attrs: { value: "tab-" + index } },
            [
              _c(
                "div",
                [
                  _c(page.component, {
                    ref: "component",
                    refInFor: true,
                    tag: "component",
                    attrs: {
                      data: _vm.notificacoes,
                      loading: _vm.notificacoesLoading,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }