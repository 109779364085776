<template>
  <div id="visaogeral">
    <the-toolbar title="Visão Geral">
      <v-btn icon @click="dialogFiltro = true"> </v-btn>
    </the-toolbar>
    <v-tabs
      style="height: 68px"
      class="visao-tabs"
      v-model="tab"
      background-color="transparent"
      centered
      lighter
      icons-and-text
    >
      <v-tab
        style="height: 85px"
        class="py-2"
        v-for="(page, index) in pages"
        :key="index"
        :href="`#tab-${index}`"
      >
        <div class="tab-name" v-html="page.nome"></div>
        <v-badge
          :color="
            page.nome === 'Alterações' && notificacoesAlertas > 0
              ? 'red'
              : 'transparent'
          "
          overlap
          :content="
            page.nome === 'Alterações' && notificacoesAlertas > 0
              ? notificacoesAlertas
              : ''
          "
        >
          <v-icon>{{ page.icon }} </v-icon></v-badge
        >
      </v-tab>
    </v-tabs>
    <div class="border-tabs"></div>
    <v-tabs-items v-model="tab" class="elevation-0">
      <v-tab-item
        v-for="(page, index) in pages"
        :key="index"
        :value="'tab-' + index"
      >
        <div>
          <component
            ref="component"
            :is="page.component"
            :data="notificacoes"
            :loading="notificacoesLoading"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import relatoriosService from "@/services/relatoriosService";

import TheToolbar from "@/components/TheToolbar.vue";
import Dashboard from "./Dashboard.vue";
import Informacoes from "./Informacoes.vue";
import Notificacoes from "./Notificacoes.vue";

export default {
  data() {
    return {
      tab: 0,
      notificacoes: [],
      notificacoesLoading: false,
      notificacoesAlertas: 0,
      pages: [
        { nome: "Visão Geral", component: Dashboard, icon: "mdi-chart-bar" },
        { nome: "Tutoriais", component: Informacoes, icon: "mdi-information" },
      ],
    };
  },
  created() {
    this.getNotificacoes();
  },
  components: { TheToolbar },
  methods: {
    async getNotificacoes() {
      this.loadingNotificacoe = true;
      try {
        this.notificacoes = await relatoriosService.alteracoesPropostas();
        this.notificacoes.forEach((notificacao) => {
          notificacao.cor = this.getColor(notificacao.statusProposta);
          if (!notificacao.visto) this.notificacoesAlertas++;
        });
        this.loadingNotificacoe = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      }
    },
    getColor(status) {
      switch (status) {
      case "contrato_assinado":
      case "pronta_transmissao":
        return "blue";
      case "devolvida":
        return "deep-orange";
      case "implantada":
        return "green";
      case "finalizada":
        return "lime";
      default:
        return "grey";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#visaogeral {
  .border-tabs {
    height: 3px;
    width: 100%;
    background: #f3f3f3;
    margin: 2px 0 0 0;
    position: relative;
  }

  .v-item-group {
    position: relative;
    z-index: 5;
  }
}
</style>
