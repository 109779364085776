<template>
  <div>
    <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
    </div>
    <div v-else id="page-dash" class="col-11 col-sm-7 col-md-12 col-lg-11 col-xl-8 mx-auto mb-5">
      <div class="my-7">
        Acompanhe em tempo real às estatísticas das suas propostas de
        <strong>{{ formatData(periodo.dataInicial) }}</strong>
        à <strong>{{ formatData(periodo.dataFinal) }}</strong>.
        <v-btn dark small color="primary" class="ml-3" @click="showDialogPeriodo = true">
          <span class="mx-3 mt-1">
            Alterar datas
          </span>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="primary--text"> Seu painel </v-card-title>
            <v-card-text>
              <div v-if="showMsgSeuPainel" class="div-msg">
                <span>Você ainda não tem propostas.</span>
              </div>
              <echart v-else :options="graficoSeuPainel" :auto-resize="true"></echart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="primary--text"> Em andamento </v-card-title>
            <v-card-text>
              <div v-if="showMsgEmAndamento" class="div-msg">
                <span>Não existem propostas em andamento.</span>
              </div>
              <echart v-else :options="graficoEmAndamento" :auto-resize="true"></echart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="primary--text"> Em análise </v-card-title>
            <v-card-text>
              <div v-if="showMsgEmAnalise" class="div-msg">
                <span>Não existem propostas em análise.</span>
              </div>
              <echart v-else :options="graficoEmAnalise" :auto-resize="true"></echart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="primary--text"> Histórico </v-card-title>
            <v-card-text>
              <div v-if="showMsgHistorico" class="div-msg">
                <span>Não existem propostas no histórico.</span>
              </div>
              <echart v-else :options="graficoHistorico" :auto-resize="true"></echart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) in itens"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card>
            <v-sheet
              :color="item.cor"
              elevation="1"
              height="5"
              rounded
              width="100%"
            ></v-sheet>
            <div class="d-flex justify-space-between align-center grey lighten-5">
              <v-card-title class="d-flex text--primary">
                <span :style="`color:${item.cor}`">{{ item.titulo }}</span>
              </v-card-title>
              <v-chip class="mr-4 white--text" :color="item.cor" label>{{
                item.quantidade
              }}</v-chip>
            </div>
            <v-divider></v-divider>
            <div class="d-flex justify-space-between align-center">
              <div>
                <v-card-text>{{
                  item.valorTotalProposta | currency
                }}</v-card-text>
              </div>
              <v-chip
                v-if="item.detalhes.length > 0"
                class="mr-4 cursor-pointer"
                color="grey lighten-3"
                label
                @click="abrirDetalhes(index)"
                >ver detalhe</v-chip
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog ref="dialogPeriodo" v-model="showDialogPeriodo" persistent width="290px">
        <v-date-picker v-model="periodo.dataInicioFim" scrollable range locale="pt" selected-items-text="2 selecionadas">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="clearData"> Limpar </v-btn>
          <v-btn text color="primary" @click="showDialogPeriodo = false"> Cancelar </v-btn>
          <v-btn text color="primary" @click="applyDataInicioFim"> Aplicar </v-btn>
        </v-date-picker>
      </v-dialog>
      <v-dialog v-model="dialog.show" width="600">
        <div class="white">
          <v-toolbar dark color="primary" dense class="elevation-0">
            <v-btn icon dark @click="dialog.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog.content.titulo }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div class="d-flex justify-space-between grey lighten-5 py-2">
            <div class="ml-4">
              <div class="text-caption">quantidade</div>
              <div class="text-body-2">{{ dialog.content.quantidade }}</div>
            </div>
            <div class="mr-4">
              <div class="text-caption">Valor</div>
              <div class="text-body-2">
                {{ dialog.content.valorTotalProposta | currency }}
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="white py-4 px-6">
            <v-card
              class="mx-auto mt-6"
              outlined
              v-for="(item, index) in dialog.content.detalhes"
              :key="index"
            >
              <div class="d-flex justify-space-between align-center">
                <v-card-text>
                  <div class="text-h6">{{ item.nomeAmigavel }}</div>
                  <div class="subtitle-1" :class="`${dialog.content.cor}--text`">
                    {{ item.valorTotalProposta | currency }}
                  </div>
                </v-card-text>
                <v-progress-circular
                  class="mr-3"
                  :rotate="360"
                  :size="60"
                  :width="5"
                  :value="getValue(item.quantidade)"
                  :color="dialog.content.cor"
                >
                  {{ item.quantidade }}
                </v-progress-circular>
              </div>
            </v-card>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import relatoriosService from "@/services/relatoriosService";
import dashboardService from "@/services/dashboardService";
import moment from "moment";
import { find } from "lodash";

import ECharts from "vue-echarts/components/ECharts.vue";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";

export default {
  name: "Dashboard",
  components: { echart: ECharts },
  data() {
    return {
      dialog: {
        content: [],
        show: false,
      },
      itens: [],
      items: [
        {
          titulo: "Simulações",
          quantidade: 7,
          valor: 5260.58,
          detalhes: null,
        },
        {
          titulo: "Propostas em Andamento",
          quantidade: 269,
          valor: 5260.58,
          detalhes: [
            {
              titulo: "proposta assinada",
              quantidade: 50,
              valor: 24942.46,
            },
            {
              titulo: "Em digitação",
              quantidade: 134,
              valor: 17987.2,
            },
            {
              titulo: "DPS Enviada",
              quantidade: 63,
              valor: 30970.16,
            },
            {
              titulo: "Proposta Enviada",
              quantidade: 19,
              valor: 7938.04,
            },
            {
              titulo: "DPS Recusada",
              quantidade: 1,
              valor: 448.91,
            },
            {
              titulo: "Enviar DPS",
              quantidade: 1,
              valor: 1645.35,
            },
          ],
        },
        {
          titulo: "Propstas em Aprovação",
          quantidade: 146,
          valor: 5260.58,
          detalhes: null,
        },
        {
          titulo: "Propostas Histórico",
          quantidade: 79,
          valor: 5260.58,
          detalhes: null,
        },
      ],
      showDialogPeriodo: false,
      periodo: {
        dataInicial: null,
        dataFinal: null,
        dataInicioFim: [],
      },
      quantidades: [],
      showMsgSeuPainel: false,
      showMsgEmAndamento: false,
      showMsgEmAnalise: false,
      showMsgHistorico: false,
      graficoEmAnalise: {},
      graficoEmAndamento: {},
      graficoHistorico: {},
      graficoSeuPainel: {},
      loading: false,
    };
  },
  created() {
    this.setDataInicioFimDefault();
    this.getDados();
  },
  computed: {
  },
  methods: {
    setDataInicioFimDefault() {
      const dataInicial = moment().subtract(30, "days").format("YYYY-MM-DD");
      const dataFinal = moment().format("YYYY-MM-DD");
      this.periodo = {
        ...this.periodo,
        dataInicial,
        dataFinal,
        dataInicioFim: [dataInicial, dataFinal]
      };
    },
    abrirDetalhes(index) {
      this.dialog.content = this.itens[index];
      this.dialog.show = true;
    },
    getValue(quantidade) {
      return (quantidade * 100) / this.dialog.content.quantidade;
    },
    async getItems() {
      const itens = [];
      let index = 0;
      const etapas = [
        {
          id: "simulacao",
          titulo: "Simulações",
          status: ["Simulações"],
          cor: "#FF4162",
        },
        {
          id: "andamento",
          titulo: "Propostas em Andamento",
          status: ["EM_DIGITACAO"],
          cor: "#00CFB6",
        },
        {
          id: "em_aprovacao",
          titulo: "Propostas em Aprovação",
          status: ["EM_ANALISE", "DEVOLVIDA", "FILA_TRANSMISSAO"],
          cor: "#FFB61E",
        },
        {
          id: "historico",
          titulo: "Propostas Histórico",
          status: ["IMPLANTADA", "FINALIZADA", "NAO_RECOMENDADA", "CANCELADA"],
          cor: "#171A88",
        },
      ];

      etapas.forEach((etapa) => {
        itens.push({
          etapa: etapa.id,
          titulo: etapa.titulo,
          cor: etapa.cor,
          detalhes: [],
          valorTotalProposta: 0,
          quantidade: 0,
        });
      });
      const result = await relatoriosService.visaoGeral(this.periodo);
      result.forEach((item) => {
        index = 0;
        etapas.forEach((etapa) => {
          if (
            etapa.status.includes(item.statusAtual)
            || etapa.status.includes(item.nomeAmigavel)
          ) {
            itens[index].detalhes.push(item);
            itens[index].valorTotalProposta += item.valorTotalProposta;
            itens[index].quantidade += item.quantidade;
          }
          index++;
        });
      });
      this.itens = itens;
    },
    async buscarDados() {
      const { result } = await dashboardService.listar(this.periodo);
      this.quantidades = result;
      this.carregarGraficos();
    },
    async carregarGraficos() {
      const [graficoEmAnalise, graficoEmAndamento, graficoHistorico, graficoSeuPainel] = await Promise.all([this.grafEmAnalise(), this.grafEmAndamento(), this.grafHistorico(), this.grafSeuPainel()]);
      this.graficoEmAnalise = graficoEmAnalise;
      this.graficoEmAndamento = graficoEmAndamento;
      this.graficoHistorico = graficoHistorico;
      this.graficoSeuPainel = graficoSeuPainel;
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    async getDados() {
      try {
        this.loading = true;
        await this.buscarDados();
        await this.getItems();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar dados, tente novamente mais tarde.",
        });
      } finally {
        this.loading = false;
        this.showDialogPeriodo = false;
      }
    },
    clearData() {
      this.setDataInicioFimDefault();
      this.getDados();
    },
    applyDataInicioFim() {
      const { dataInicioFim } = this.periodo;
      if (dataInicioFim && dataInicioFim.length >= 2) {
        const sortedDates = dataInicioFim.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const momentStartDate = moment(sortedDates[0], "YYYY-MM-DD");
        const momentEndDate = moment(sortedDates[1], "YYYY-MM-DD");
        const diffDays = moment.duration(momentEndDate.diff(momentStartDate)).asDays();

        if (diffDays <= 90) {
          this.periodo = {
            ...this.periodo,
            dataInicial: sortedDates[0],
            dataFinal: sortedDates[1],
            dataInicioFim: [sortedDates[0], sortedDates[1]]
          };
          this.getDados();
          this.showDialogPeriodo = false;
        } else {
          this.$root.$snackBar.open({
            color: "warning",
            message: "A diferença entre datas não pode ultrapassar 90 dias.",
          });
        }
      }
    },
    grafSeuPainel() {
      if (this.quantidades && this.quantidades.length) {
        const { result } = find(this.quantidades, { aba: "TODOS" });
        const totalEmAndamento = find(result, {
          status: "Em Andamento"
        });
        const totalEmAnalise = find(result, {
          status: "Em aprovação"
        });
        const totalHistorico = find(result, {
          status: "Histórico"
        });

        if (!totalEmAndamento && !totalEmAnalise && !totalHistorico) {
          this.showMsgSeuPainel = true;
          return {};
        } else this.showMsgSeuPainel = false;

        return {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          xAxis: {
            type: "category",
            data: ["Em andamento", "Em análise", "Histórico"],
            axisLabel: {
              interval: 0
            }
          },
          yAxis: {
            type: "value"
          },
          series: [
            {
              data: [
                {
                  value:
                    totalEmAndamento && totalEmAndamento.total
                      ? totalEmAndamento.total
                      : 0,
                  itemStyle: {
                    color: "#00CFB6"
                  }
                },
                {
                  value:
                    totalEmAnalise && totalEmAnalise.total
                      ? totalEmAnalise.total
                      : 0,
                  itemStyle: {
                    color: "#171A88"
                  }
                },
                {
                  value:
                    totalHistorico && totalHistorico.total
                      ? totalHistorico.total
                      : 0,
                  itemStyle: {
                    color: "#FFB61E"
                  }
                }
              ],
              type: "bar",
              label: {
                show: true,
                position: "top"
              }
            }
          ]
        };
      } else return {};
    },
    grafEmAndamento() {
      if (this.quantidades && this.quantidades.length) {
        const { result } = find(this.quantidades, { aba: "EM_ANDAMENTO" });
        const totalDadosPlano = find(result, {
          status: "dados_plano"
        });
        const totalDadosTitular = find(result, {
          status: "dados_titular"
        });
        const totalAguardandoAssinatura = find(result, {
          status: "aguardando_assinatura"
        });
        const totalProntaTransmissao = find(result, {
          status: "pronta_transmissao"
        });

        if (
          !totalDadosPlano &&
          !totalDadosTitular &&
          !totalAguardandoAssinatura &&
          !totalProntaTransmissao
        ) {
          this.showMsgEmAndamento = true;
          return {};
        } else this.showMsgEmAndamento = false;

        return {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          xAxis: {
            type: "category",
            data: [
              "Dados do\n plano",
              "Dados do\n titular",
              "Aguardando\n assinatura",
              "Pronta para\n transmissão"
            ],
            axisLabel: {
              interval: 0
            }
          },
          yAxis: {
            type: "value"
          },
          series: [
            {
              data: [
                {
                  value:
                    totalDadosPlano && totalDadosPlano.total
                      ? totalDadosPlano.total
                      : 0,
                  itemStyle: {
                    color: "#00CFB6"
                  }
                },
                {
                  value:
                    totalDadosTitular && totalDadosTitular.total
                      ? totalDadosTitular.total
                      : 0,
                  itemStyle: {
                    color: "#FF4162"
                  }
                },
                {
                  value:
                    totalAguardandoAssinatura && totalAguardandoAssinatura.total
                      ? totalAguardandoAssinatura.total
                      : 0,
                  itemStyle: {
                    color: "#171A88"
                  }
                },
                {
                  value:
                    totalProntaTransmissao && totalProntaTransmissao.total
                      ? totalProntaTransmissao.total
                      : 0,
                  itemStyle: {
                    color: "#FFB61E"
                  }
                }
              ],
              type: "bar",
              label: {
                show: true,
                position: "top"
              }
            }
          ]
        };
      } else return {};
    },
    grafEmAnalise() {
      if (this.quantidades && this.quantidades.length) {
        const { result } = find(this.quantidades, { aba: "EM_APROVACAO" });
        const totalEmAnalise = find(result, {
          status: "EM_ANALISE"
        });
        const totalDevolvida = find(result, {
          status: "DEVOLVIDA"
        });

        if (!totalEmAnalise && !totalDevolvida) {
          this.showMsgEmAnalise = true;
          return {};
        } else this.showMsgEmAnalise = false;

        return {
          tooltip: {
            trigger: "item"
          },
          series: [
            {
              name: "Em análise",
              type: "pie",
              radius: "70%",
              data: [
                {
                  value:
                    totalEmAnalise && totalEmAnalise.total
                      ? totalEmAnalise.total
                      : 0,
                  itemStyle: {
                    color: "#00CFB6"
                  },
                  name: "Em análise"
                },
                {
                  value:
                    totalDevolvida && totalDevolvida.total
                      ? totalDevolvida.total
                      : 0,
                  itemStyle: {
                    color: "#FF4162"
                  },
                  name: "Devolvida"
                }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              },
              label: {
                formatter: "{b}: {@2012}"
              }
            }
          ]
        };
      } else return {};
    },
    grafHistorico() {
      if (this.quantidades && this.quantidades.length) {
        const { result } = find(this.quantidades, { aba: "HISTORICO" });
        const totalImplantada = find(result, {
          status: "IMPLANTADA"
        });
        const totalFinalizada = find(result, {
          status: "FINALIZADA"
        });

        if (!totalImplantada && !totalFinalizada) {
          this.showMsgHistorico = true;
          return {};
        } else this.showMsgHistorico = false;

        return {
          tooltip: {
            trigger: "item"
          },
          series: [
            {
              name: "Histórico",
              type: "pie",
              radius: "70%",
              data: [
                {
                  value:
                    totalImplantada && totalImplantada.total
                      ? totalImplantada.total
                      : 0,
                  itemStyle: {
                    color: "#FFB61E"
                  },
                  name: "Implantada"
                },
                {
                  value:
                    totalFinalizada && totalFinalizada.total
                      ? totalFinalizada.total
                      : 0,
                  itemStyle: {
                    color: "#171A88"
                  },
                  name: "Finalizada"
                }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              },
              label: {
                formatter: "{b}: {@2012}"
              }
            }
          ]
        };
      } else return {};
    }
  },
};
</script>
<style lang="scss">
.echarts {
  height: 250px !important;
  width: 100% !important;
}

.div-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15.5rem;
}

#page-dash {
  .v-card__title {
    line-height: 16px;
  }
  @media (max-width: 450px) {
    .v-card__title {
      font-size: smaller;
    }
    .v-chip {
      span {
        justify-content: center !important;
      }
    }
  }
}
</style>
