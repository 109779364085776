var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-divider"),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 col-11 mx-auto" },
          _vm._l(_vm.conteudo[_vm.tabAtiva], function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "sobre-itens my-6" },
              [
                _c(
                  "div",
                  { staticClass: "badge-icon" },
                  [
                    _vm.tabAtiva == "tutorial"
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              color: index % 2 == 0 ? "white" : "primary",
                              large: "",
                            },
                          },
                          [_vm._v("mdi-youtube")]
                        )
                      : _c(
                          "v-icon",
                          {
                            attrs: {
                              color: index % 2 == 0 ? "white" : "primary",
                              large: "",
                            },
                          },
                          [_vm._v("mdi-file-pdf")]
                        ),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  {
                    staticClass: "elevation-3",
                    class: index % 2 == 0 ? "primary" : "white",
                    attrs: { dark: index % 2 == 0 },
                  },
                  [
                    _c("v-card-title", { staticClass: "pt-7" }, [
                      _vm._v(_vm._s(item.titulo) + " "),
                    ]),
                    _c("v-card-text", { staticClass: "quebra-texto" }, [
                      _vm._v(_vm._s(item.descricao)),
                    ]),
                    _vm.tabAtiva === "tutorial"
                      ? _c("div", { staticClass: "text-center pb-2" }, [
                          _c("iframe", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: "95%",
                              height: "315",
                              src: `https://www.youtube.com/embed/${item.destinoConteudo}`,
                              frameborder: "0",
                              allow:
                                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                              allowfullscreen: "",
                            },
                          }),
                        ])
                      : _vm.tabAtiva === "manual"
                      ? _c(
                          "div",
                          { staticClass: "text-center pb-2" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: index % 2 == 0 ? "white" : "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.abrirArquivo(
                                      item.destinoConteudo
                                    )
                                  },
                                },
                              },
                              [_vm._v("ver manual")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }