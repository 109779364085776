var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "d-flex justify-center mt-10 pa-10" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary", size: "50" },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass:
              "col-11 col-sm-7 col-md-12 col-lg-11 col-xl-8 mx-auto mb-5",
            attrs: { id: "page-dash" },
          },
          [
            _c(
              "div",
              { staticClass: "my-7" },
              [
                _vm._v(
                  " Acompanhe em tempo real às estatísticas das suas propostas de "
                ),
                _c("strong", [
                  _vm._v(_vm._s(_vm.formatData(_vm.periodo.dataInicial))),
                ]),
                _vm._v(" à "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.formatData(_vm.periodo.dataFinal))),
                ]),
                _vm._v(". "),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-3",
                    attrs: { dark: "", small: "", color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.showDialogPeriodo = true
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "mx-3 mt-1" }, [
                      _vm._v(" Alterar datas "),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "primary--text" }, [
                          _vm._v(" Seu painel "),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm.showMsgSeuPainel
                              ? _c("div", { staticClass: "div-msg" }, [
                                  _c("span", [
                                    _vm._v("Você ainda não tem propostas."),
                                  ]),
                                ])
                              : _c("echart", {
                                  attrs: {
                                    options: _vm.graficoSeuPainel,
                                    "auto-resize": true,
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "primary--text" }, [
                          _vm._v(" Em andamento "),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm.showMsgEmAndamento
                              ? _c("div", { staticClass: "div-msg" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Não existem propostas em andamento."
                                    ),
                                  ]),
                                ])
                              : _c("echart", {
                                  attrs: {
                                    options: _vm.graficoEmAndamento,
                                    "auto-resize": true,
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "primary--text" }, [
                          _vm._v(" Em análise "),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm.showMsgEmAnalise
                              ? _c("div", { staticClass: "div-msg" }, [
                                  _c("span", [
                                    _vm._v("Não existem propostas em análise."),
                                  ]),
                                ])
                              : _c("echart", {
                                  attrs: {
                                    options: _vm.graficoEmAnalise,
                                    "auto-resize": true,
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "primary--text" }, [
                          _vm._v(" Histórico "),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm.showMsgHistorico
                              ? _c("div", { staticClass: "div-msg" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Não existem propostas no histórico."
                                    ),
                                  ]),
                                ])
                              : _c("echart", {
                                  attrs: {
                                    options: _vm.graficoHistorico,
                                    "auto-resize": true,
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              _vm._l(_vm.itens, function (item, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-sheet", {
                          attrs: {
                            color: item.cor,
                            elevation: "1",
                            height: "5",
                            rounded: "",
                            width: "100%",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center grey lighten-5",
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "d-flex text--primary" },
                              [
                                _c("span", { style: `color:${item.cor}` }, [
                                  _vm._v(_vm._s(item.titulo)),
                                ]),
                              ]
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass: "mr-4 white--text",
                                attrs: { color: item.cor, label: "" },
                              },
                              [_vm._v(_vm._s(item.quantidade))]
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-card-text", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        item.valorTotalProposta
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            item.detalhes.length > 0
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-4 cursor-pointer",
                                    attrs: {
                                      color: "grey lighten-3",
                                      label: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirDetalhes(index)
                                      },
                                    },
                                  },
                                  [_vm._v("ver detalhe")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "v-dialog",
              {
                ref: "dialogPeriodo",
                attrs: { persistent: "", width: "290px" },
                model: {
                  value: _vm.showDialogPeriodo,
                  callback: function ($$v) {
                    _vm.showDialogPeriodo = $$v
                  },
                  expression: "showDialogPeriodo",
                },
              },
              [
                _c(
                  "v-date-picker",
                  {
                    attrs: {
                      scrollable: "",
                      range: "",
                      locale: "pt",
                      "selected-items-text": "2 selecionadas",
                    },
                    model: {
                      value: _vm.periodo.dataInicioFim,
                      callback: function ($$v) {
                        _vm.$set(_vm.periodo, "dataInicioFim", $$v)
                      },
                      expression: "periodo.dataInicioFim",
                    },
                  },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: { click: _vm.clearData },
                      },
                      [_vm._v(" Limpar ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.showDialogPeriodo = false
                          },
                        },
                      },
                      [_vm._v(" Cancelar ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: { click: _vm.applyDataInicioFim },
                      },
                      [_vm._v(" Aplicar ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { width: "600" },
                model: {
                  value: _vm.dialog.show,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialog, "show", $$v)
                  },
                  expression: "dialog.show",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "white" },
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary", dense: "" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                _vm.dialog.show = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                        _c("v-toolbar-title", [
                          _vm._v(_vm._s(_vm.dialog.content.titulo)),
                        ]),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between grey lighten-5 py-2",
                      },
                      [
                        _c("div", { staticClass: "ml-4" }, [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v("quantidade"),
                          ]),
                          _c("div", { staticClass: "text-body-2" }, [
                            _vm._v(_vm._s(_vm.dialog.content.quantidade)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mr-4" }, [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v("Valor"),
                          ]),
                          _c("div", { staticClass: "text-body-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.dialog.content.valorTotalProposta
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("v-divider"),
                    _c(
                      "div",
                      { staticClass: "white py-4 px-6" },
                      _vm._l(
                        _vm.dialog.content.detalhes,
                        function (item, index) {
                          return _c(
                            "v-card",
                            {
                              key: index,
                              staticClass: "mx-auto mt-6",
                              attrs: { outlined: "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                },
                                [
                                  _c("v-card-text", [
                                    _c("div", { staticClass: "text-h6" }, [
                                      _vm._v(_vm._s(item.nomeAmigavel)),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "subtitle-1",
                                        class: `${_vm.dialog.content.cor}--text`,
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.valorTotalProposta
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-progress-circular",
                                    {
                                      staticClass: "mr-3",
                                      attrs: {
                                        rotate: 360,
                                        size: 60,
                                        width: 5,
                                        value: _vm.getValue(item.quantidade),
                                        color: _vm.dialog.content.cor,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.quantidade) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }